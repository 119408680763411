<template>
  <div>
    <div class="content d-flex row-center">
      <a href="https://beian.miit.gov.cn/"> 蜀ICP备2022013315号 </a>
    </div>
  </div>
</template>
<style scoped>
a {
  text-decoration: none;
  color: #333;
}
a:hover {
  color: blue;
  text-decoration: underline;
}
.content {
  padding-top: 20px;
  border-top: 1px solid #eee;
  letter-spacing: 1px;
  font-size: 14px;
}
</style>
