import storage from "@/utils/storage";

const USERINFO = storage.get("loginUserInfo");
export default {
  namespace: true,
  state() {
    return {
      userInfo: USERINFO || {},
    };
  },
  mutations: {
    saveUserInfo(state: any, userInfo: any) {
      state.userInfo = userInfo;
    },
    clearUserInfo(state: any) {
      state.userInfo = undefined;
    },
  },
  getters: {
    userName(state: any) {
      return state.userInfo.nickname;
    },
    account(state: any) {
      return state.userInfo.account;
    },
    photo(state: any) {
      return state.userInfo.photo;
    },
  },
};
