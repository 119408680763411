import storage from "@/utils/storage";
import { ElMessage } from "element-plus";
import {
  createRouter,
  createWebHashHistory,
  // createWebHistory,
} from "vue-router";
//引入nprogress
import NProgress from 'nprogress';
import 'nprogress/nprogress.css' //这个样式必须引入

// 1. 定义路由组件， 注意，这里一定要使用 文件的全名（包含文件后缀名）
import login from "../views/login.vue";
import layout from "../layout/index.vue";

// 2. 定义路由配置
const routes = [
  { path: "/", redirect: "/login" },
  { path: "/login", name: "login", component: login },
  {
    path: "/layout",
    name: "layout",
    component: layout,
    redirect: '/layout/workbench',
    children: [
      {
        path: "workbench",
        name: "workbench",
        meta: {
          name: ['工作台'],

        },
        component: () => import("../views/workbench/workbench.vue"),
      },
      {
        path: "meter-reading",
        name: "meter-reading",
        component: () => import("../views/statistics/meter-reading/index.vue"),
        children: [
          {
            path: "amount",
            name: "amount",
            meta: {
              name: ['统计查询', '计数抄表查询'],
              highlight: '/layout/meter-reading/amount'
            },
            component: () => import("../views/statistics/meter-reading/amount.vue"),
          },
          {
            path: "quota",
            name: "quota",
            meta: {
              name: ['统计查询', '定额抄表记录'],
              highlight: '/layout/meter-reading/quota'
            },
            component: () => import("../views/statistics/meter-reading/quota.vue"),

          },
          {
            path: "notice",
            name: "notice",
            meta: {
              name: ['统计查询', '通知单查询']
            },
            component: () => import("../views/statistics/meter-reading/notice.vue"),
          },
          {
            path: "voucher",
            name: "voucher",
            meta: {
              name: ['统计查询', '收费凭证查询']
            },
            component: () => import("../views/statistics/meter-reading/voucher.vue"),
          }]
      },
      {
        path: "manual-operation",
        name: "manual-operation",
        meta: {
          name: ['人工统计数据']
        },
        component: () => import("../views/manual-operation/list.vue"),
      },
      {
        path: "manual-operation-add",
        name: "manual-operation-add",
        meta: {
          name: ['人工统计数据', '编辑人工修正记录'],
          highlight: '/layout/manual-operation'
        },
        component: () => import("../views/manual-operation/add-record.vue"),
      },
      {
        path: "bill",
        name: "bill",
        meta: {
          name: ['账单查询']
        },
        component: () => import("../views/invoice/bill.vue"),
      },
      {
        path: "selectedBill",
        name: "selectedBill",
        meta: {
          name: ['账单查询', '选择的账单'],
          highlight: '/layout/bill'
        },
        component: () => import("../views/invoice/selectedBill.vue"),
      },
      {
        path: "invoice-list",
        name: "invoice-list",
        meta: {
          name: ['水表管理']
        },
        component: () => import("../views/invoice/invoice.vue"),
      },
      {
        path: "water-meter",
        name: "water-meter",
        meta: {
          name: ['水表管理']
        },
        component: () => import("../views/water-meter/index.vue"),
      },
      {
        path: "water-meter-add",
        name: "water-meter-add",
        meta: {
          name: ['水表管理', '新增水表'],
          highlight: '/layout/water-meter'
        },
        component: () => import("../views/water-meter/water-meter-add.vue"),
      },
      {
        path: "water-meter-edit",
        name: "water-meter-edit",
        meta: {
          name: ['水表管理', '修改水表'],
          highlight: '/layout/water-meter'
        },
        component: () => import("../views/water-meter/water-meter-edit.vue"),
      },
      {
        path: "water-meter-all-edit",
        name: "water-meter-all-edit",
        meta: {
          name: ['水表管理', '批量修改水表'],
          highlight: '/layout/water-meter'
        },
        component: () => import("../views/water-meter/water-meter-all-edit.vue"),
      },
      {
        path: "personnel",
        name: "personnel",
        meta: {
          name: ['人员管理']
        },
        component: () => import("../views/personnel/index.vue"),
      },
      {
        path: "personnel-change",
        name: "personnel-change",
        meta: {
          name: ['人员管理', '人员修改'],
          highlight: '/layout/personnel'
        },
        component: () => import("../views/personnel/personnel-change.vue"),
      },
      {
        path: "area",
        name: "area",
        meta: {
          name: ['区域管理']
        },
        component: () => import("../views/area/index.vue"),
      },
      {
        path: "message",
        name: "message",
        meta: {
          name: ['消息中心']
        },
        component: () => import("../views/message/message.vue"),
      },
      {
        path: "setting",
        name: "setting",
        meta: {
          name: ['人员设置']
        },
        component: () => import("../views/setting/index.vue"),
      },
    ],
  },
  {
    path: '/*',
    name: '404',
    component: () => import("../views/error-page/404.vue"),
  }
];

// 3. 创建路由实例
const router = createRouter({
  // 4. 采用hash 模式
  history: createWebHashHistory(),
  // 采用 history 模式
  // history: createWebHistory(),
  routes, // short for `routes: routes`
});

const whiteList = ['/login'];
router.beforeEach((to, from, next) => {

  const userInfo = storage.get("loginUserInfo");
  if ((userInfo && userInfo.accessToken) || whiteList.includes(to.path)) {
    NProgress.start()
    next()
  } else {
    ElMessage.error('没有权限！')
    router.push({
      path: "/login",
    });
  }

})

router.afterEach(() => {
  NProgress.done()
})

export default router;
