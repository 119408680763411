//数据校验，需要不断更新

//name必须有,其他都是有这个字段才校验

//使用方法：
// let rules = {
//   name:{
//      type: ["Number", "String","Phone","Array","EmptyArray","Object","EmptyObject","RegExp","Boolean"],type的参数是：校验规则的名字去掉is，满足其中一个就通过校验
//      types:[],跟上面一样（都满足才通过校验）
//      name:'名字', //跟数据对应的名字
//      reg:'/d/', //正则
//      min:'/d/', //正则
//      max:'/d/', //正则
//      errMsg: "111", //出错时的提示
//      required: true, //是否必填，默认true
//   },
// };

// let data = {
//   name: "11",
//   age: 11,
// };
// let validator = new Validator
// let [errMsg,errMsgArr] = validator.validate(data, rules);
//  [errMsg,errMsgArr]  如果验证通过，errMsg为null，如果没有通过，errMsg为第一条报错的错误提示，errMsgArr为所有的报错提示

class Validator {
  constructor() {
    this.errArr = [];
    this.correctArr = [];
  }
  validate(data, rules) {
    //对rules进行一些处理
    //设置一些默认值
    for (let key in rules) {
      let item = rules[key];
      //如果直接是字符串就转成Sting和必填的对象(其实就是string类型为必填的简写模式)
      if (this.isString(item)) {
        rules[key] = {
          type: "String",
          name: item,
          required: true,
        };
      } else {
        //required不存在默认为true
        if (!Object.prototype.hasOwnProperty.call(item, "required")) {
          item.required = true;
        }
        //type不存在默认为true
        if (!Object.prototype.hasOwnProperty.call(item, "type")) {
          item.type = "String";
        }
      }
    }

    this.errArr = [];
    //data：数据，rules：规则
    for (let key in rules) {
      let isRight = true; //是否符合当前的规则
      let rule = rules[key];
      let val = data[key];
      // 添加到错误数组里
      let addErr = (errType, obj) => {
        let errObj = {};
        Object.assign(
          errObj,
          rule,
          {
            errMsg: rule.errMsg || rule.name + "格式错误",
          },
          obj,
          {
            errType,
          }
        );
        this.errArr.push(errObj);
      };

      //required--------------------------
      if (Object.prototype.hasOwnProperty.call(rule, "required")) {
        if (!this.isBoolean(rule.required)) {
          throw "required必须是一个布尔值";
        }
        //如果规则里的required为true(就必须有这个值)
        if (
          rule.required &&
          (val === "" || val === undefined || val === null)
        ) {
          addErr("required", {
            errMsg: rule.name + "是必填项",
          });
          continue;
        }
      }

      //min--------------------------
      if (val && Object.prototype.hasOwnProperty.call(rule, "min")) {
        if (!this.isNumber(rule.min)) {
          throw rule.name + "必须是一个数字";
        }

        if (rule.min) {
          if (val.length < rule.min) {
            addErr("min", {
              errMsg: rule.name + "的长度不能小于" + rule.min,
            });
            continue;
          }
        }
      }

      //max--------------------------
      if (val && Object.prototype.hasOwnProperty.call(rule, "max")) {
        if (!this.isNumber(rule.max)) {
          if (!this.isNumber(rule.min)) {
            throw rule.name + "必须是一个数字";
          }
        }

        if (rule.max) {
          if (val.length > rule.max) {
            addErr("max", {
              errMsg: rule.name + "的长度不能大于" + rule.max,
            });
            continue;
          }
        }
      }

      //type------------------------------
      if (val && Object.prototype.hasOwnProperty.call(rule, "type")) {
        if (!this.isString(rule.type) && !this.isArray(rule.type)) {
          throw "type必须是一个字符串或者数组";
        }
        //如果type是字符串
        if (this.isString(rule.type)) {
          console.log(
            "🚀 ~ file: lazy-validator.js ~ line 138 ~ rule.type",
            rule.type,
            val
          );
          isRight = this[`is${rule.type}`](val);
        }
        //如果type是数组
        if (this.isArray(rule.type)) {
          let flag = false;
          for (let type of rule.type) {
            if (this[`is${type}`](val)) {
              flag = true;
            }
          }
          isRight = flag;
        }

        // 不符合规则就添加到错误数组里
        if (!isRight) {
          addErr("type");
          continue;
        }
      }

      //reg ------------------------------
      if (val && rule.reg) {
        if (!this.isRegExp(rule.reg)) {
          throw "reg必须是一个正则";
        }
        if (!rule.reg.test(val)) {
          addErr("reg");
          continue;
        }
      }
    }

    // 没有错误的返回
    if (this.errArr.length <= 0) {
      return [null];
    }
    //有错误时的返回
    return [this.errArr[0].errMsg, this.errArr];
  }

  // 校验规则-------------------

  //是否是数字
  isNumber(val) {
    return this.varType(val) === "Number";
  }

  //是否是字符串
  isString(val) {
    return this.varType(val) === "String";
  }

  isArray(val) {
    return this.varType(val) === "Array";
  }

  //是否是空数组
  isEmptyArray(val) {
    if (this.varType(val) !== "Array") {
      return false;
    }
    if (val.length === 0) {
      return true;
    }
  }

  //是否是对象
  isObject(val) {
    return this.varType(val) === "Object";
  }

  //是否是空对象
  isEmptyObject(val) {
    if (this.varType(val) !== "Object") {
      return false;
    }
    var objStr = JSON.stringify(val);
    return objStr === "{}";
  }

  //是否是手机号
  isPhone(val) {
    let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    return reg.test(val);
  }

  //是否符合正则校验
  isRegExp(val) {
    return this.varType(val) === "RegExp";
  }

  //是否是布尔值
  isBoolean(val) {
    return this.varType(val) === "Boolean";
  }

  //是否是手机号
  // isChinese(val) {
  //   let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
  //   return reg.test(val);
  // }

  //判断数据类型
  varType(val) {
    return Object.prototype.toString.call(val).slice(8, -1);
  }
}
export default Validator;

