import { createStore } from "vuex";
import userInfo from "@/store/modules/userInfo";
// 创建一个新的 store 实例
const store = createStore({
  state() {
    return {};
  },
  mutations: {},
  modules: {
    userInfo
  }
});

export default store;
