import request from "@/utils/request.js";

/**
 * @description: 获取所有区域名字及其对应编号
 * @param {*} query
 */
interface loginParams {
  account: string,
  password: string
}
export function login(params: loginParams) {
  return request({
    url: "/api/Auth/Login",
    method: "post",
    data: params,
  });
}
