import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);

//引入element ui库
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from 'element-plus/lib/locale/lang/zh-cn' //中文
// 引入路由对象实例
import router from "./router/index";
import store from "./store/index";

//引入全局属性，方法
import storage from "@/utils/storage";
import validator from "@/utils/validator";
app.config.globalProperties.$storage = storage;
app.config.globalProperties.$validator = validator;

//引入全局组件
import Pagination from './components/Pagination.vue'
app.component('Pagination', Pagination);

app.use(router);
app.use(ElementPlus, { locale });
app.use(store);
app.mount("#app");
