import request from "@/utils/request.js";

/**
 * @description: 添加用户
 */
interface IAddUser {
  account: string,
  password: string,
  nickname: string,
  gender: number
}
export function addUser(params: IAddUser) {
  return request({
    url: "/api/User/AddUser",
    method: "post",
    data: params,
  });
}

/**
 * @description: 获取用户列表
 */
interface IListUser {
  MaxCount: number,
  SkipCount: number
}
export function listUser(params: IListUser) {
  return request({
    url: "/api/User/ListUser",
    method: "get",
    params,
  });
}

/**
 * @description: 获取单个用户信息
 */
export function getUserInfo(id: number) {
  return request({
    url: "/api/User/User",
    method: "get",
    params: { id },
  });
}


/**
 * @description: 获取指定用户所有权限
 */
export function getListUserRole(id: number) {
  return request({
    url: "/api/User/ListUserRole",
    method: "get",
    params: { id },
  });
}

/**
 * @description: 获取指定用户所有管辖区域
 */
export function getSystemUserRole() {
  return request({
    url: "/api/User/GetSystemUserRole",
    method: "get",
  });
}
/**
 * @description: 获取指定用户所有管辖区域
 */
export function getListUserArea(id: number) {
  return request({
    url: "/api/User/ListUserArea",
    method: "get",
    params: { id },
  });
}

/**
 * @description: 更改用户信息
 */
interface IUpdateUserInfo {
  id: number,
  nickname: string,
  gender: number,
  enabled: boolean
}
export function updateUserInfo(data: IUpdateUserInfo) {
  return request({
    url: "/api/User/UpdateUserInfo",
    method: "post",
    data
  });
}

/**
* @description: 管理员修改其他用户的密码
*/
interface IAdminResetUserPassword {
  resetPassword: string,
  userId: number
}
export function adminResetUserPassword(data: IAdminResetUserPassword) {
  return request({
    url: "/api/User/AdminResetUserPassword",
    method: "post",
    data
  });
}


/**
* @description: 更改用户区域
*/
interface IChangeUserArea {
  areaIds: number[],
  userId: number
}
export function changeUserArea(data: IChangeUserArea) {
  return request({
    url: "/api/User/ChangeUserArea",
    method: "post",
    data
  });
}

/**
* @description: 更改用户角色（权限）
*/
interface IChangeUserRole {
  roleIds: number[],
  userId: number
}
export function changeUserRole(data: IChangeUserRole) {
  return request({
    url: "/api/User/ChangeUserRole",
    method: "post",
    data
  });
}


/**
* @description: 更改当前用户的密码
*/
interface IResetUserPassword {
  resetPassword: string,
  oldPassword: boolean
}
export function resetUserPassword(data: IResetUserPassword) {
  return request({
    url: "/api/User/ResetUserPassword",
    method: "post",
    data
  });
}