import axios from "axios";
import { ElMessage } from "element-plus";
import storage from "@/utils/storage";
import router from "../router/index";
import NProgress from "nprogress";
import "nprogress/nprogress.css"; //这个样式必须引入
// let loadingInstance;
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: process.env.VUE_APP_TEST_API,
  // baseURL: process.env.VUE_APP_DEV_API,
  // baseURL: "https://api.xjjtgps.com/",
  timeout: 15000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    NProgress.start();
    // loadingInstance = ElLoading.service({ fullscreen: true });
    let userInfo = storage.get("loginUserInfo");
    if (userInfo && userInfo.accessToken) {
      config.headers["Authorization"] = "Bearer " + userInfo.accessToken;
    }
    return config;
  },
  (error) => {
    NProgress.done();
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    NProgress.done();
    // loadingInstance.close();
    const res = response.data;
    if (!res.succeeded) {
      if (typeof res.errors === "object") {
        ElMessage.error("服务器报错！");
      } else {
        ElMessage.error(res.errors);
      }
    }
    return res;
  },
  (res) => {
    NProgress.done();
    // loadingInstance.close();

    if (res.response) {
      ElMessage.error("服务器报错！");
      if (res.response.status === 401) {
        storage.remove("loginUserInfo");
        storage.remove("selectedBill");
        router.push({
          path: "/login",
        });
      }
    } else {
      ElMessage.error("服务器超时！");
    }

    return Promise.reject(res);
  }
);

export default service;
